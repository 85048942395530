<template>
    <div class="header-module-content">
        <div class="header-image-wrapper">
            <a v-if="config.link && config.link != ''" :href="config.link">
                <img
                class="logo"
                v-if="config.logo != ''"
                :src="getImg(config.logo)"
                alt="There should be a logo"
                />
            </a>
            <img
                class="logo"
                v-else-if="config.logo != ''"
                :src="getImg(config.logo)"
                alt="There should be a logo"
            />
        </div>
        <div class="social-links-wrapper" v-if="config.social != ''">
            <ul class="social-links">
                <li v-for="(media, i) in config.social" :key="i">
                    <a :href="media.logo + '/#'">
                        <i :class="getLogo(media.name)" />
                    </a>
                </li>
            </ul>
        </div>
        <div class="nav-wrapper" v-if="config.links != ''">
            <ul class="nav">
                <li v-for="(link, i) in config.links" :key="i" :class="'nav-link-' + i">
                    <inline-edit :link="link.path" :target="link.name" element="a" />
                </li>
            </ul>
        </div>
        <div class="title-card-wrapper" :style="getBackground">
            <div class="title-card" v-if="config.title != '' || config.subTitle != ''">
                <inline-edit
                v-if="config.title.value != ''"
                :target="config.title"
                element="h1"
                />
                <inline-edit
                v-if="config.subTitle.value != ''"
                :target="config.subTitle"
                element="h2"
                />
            </div>
        </div>
        <div class="phone-header-container">
            <i class="fas fa-bars" @click="phoneToggle()" />
        </div>
    </div>
</template>

<script>
    import * as imgManager from "@/store/imgManager";

    export default {
        props: ["config"],
        methods: {
            getLogo(name) {
                switch (name) {
                    case "Facebook":
                        return "fab fa-facebook-f";
                    case "Instagram":
                        return "fab fa-instagram";
                    case "Twitter":
                        return "fab fa-twitter";
                    case "Youtube":
                        return "fab fa-youtube";
                    case "LinkedIn":
                        return "fab fa-linkedin-in";
                    case "Tumblr":
                        return "fab fa-tumblr";
                    case "Reddit":
                        return "fab fa-reddit";
                    case "Pinterest":
                        return "fab fa-pinterest";
                    default:
                        return "";
                }
            },
            phoneToggle() {
                if (!document.getElementById("pop-up")) {
                    var main = document.getElementById("main-page");
                    var main_children = main.children;
                    for (let i = 0; i < main_children.length; i++) {
                        main_children[i].className += " blur";
                    }
                    var pop = document.createElement("div");
                    pop.id = "pop-up";

                    pop.innerHTML += "<i class='close-nav-pop fas fa-times' onclick='location.reload()'/>";
                    pop.innerHTML += "<div class='pop-up-nav-content'>";
                    for (let i = 0; i < this.config.links.length; i++) {
                        let target = this.config.links[i];
                        pop.innerHTML += "<a href='" + target.path + "'>" + target.name + "</a>";
                    }
                    pop.innerHTML += "</div>";
                    pop.innerHTML = pop.innerHTML.replace("'/'", "/");

                    main.appendChild(pop);
                }
            },
            getImg(image) {
                let img = imgManager.getImage(image);

                if (img) {
                    if (img.path) {
                        return img.path;
                    } else {
                        return img.file;
                    }
                }
            },
        },
        data() {
            return { isPhone: false };
        },
        computed: {
            getBackground() {
                if (this.config.background) {
                    let r = this.config.rgba.r;
                    let g = this.config.rgba.g;
                    let b = this.config.rgba.b;
                    let a = (1.0 * this.config.rgba.a) / 100.0;
                    return (
                    "background-image: linear-gradient( rgba(" + r + ", " + g + ", " + b + ", " + a + "), "+
                                                      " rgba(" + r + ", " + g + ", " + b + ", " + a + ") ),"+
                                                      " url('" + this.getImg(this.config.background) + "')"
                    );
                } else {
                    return "";
                }
            },
            getPhoneClass() {
                if (this.isPhone) {
                    return "header-phone-nav";
                } else {
                    return "";
                }
            },
        },
        mounted() {
            if (!this.config.logo) {
                this.config["logo"] = "";
            }
            if (!this.config.social) {
                this.config["social"] = [];
            }
            if (!this.config.links) {
                this.config["links"] = [];
            } else {
                for (let i = 0; i < this.config.links.length; i++) {
                    if (typeof this.config.links[i].name === "string") {
                        this.config.links[i].name = { value: this.config.links[i].name };
                    }
                }
            }

            if (!this.config.title) {
                this.config["title"] = "";
            }
            if (typeof this.config.title === "string") {
                this.config.title = { value: this.config.title };
            }
            if (!this.config.subTitle) {
                this.config["subTitle"] = "";
            }
            if (typeof this.config.subTitle === "string") {
                this.config.subTitle = { value: this.config.subTitle };
            }
            if (!this.config.background) {
                this.config["background"] = "";
            }
            if (!this.config.rgba) {
                this.config["rgba"] = {};
                this.config.rgba["r"] = 0;
                this.config.rgba["g"] = 0;
                this.config.rgba["b"] = 0;
                this.config.rgba["a"] = 0;
            }
        },
    };
</script>

<style>

    .blur {
        filter: blur(8px);
    }

</style>
